// import { Injectable } from '@angular/core';
// import { Subject, throwError } from 'rxjs';
// import { map } from 'rxjs/operators';
// import {ApiResponse, PmtPartner} from '../../models';
// import { ApiService, EnvService } from '../../services';


// @Injectable()

// export class PmtPartners {
//     pmtPartners: PmtPartner[] = [];

//     constructor(private apiService: ApiService,
//                 private env: EnvService) {
//         let queryString = `?sort=-surname`;
//         queryString += `&populate=vehicle,state,county,hub`;
//         this.recordRetrieve(queryString).then(res => { this.pmtPartners = res.payload; console.log(res) });
//     }

//     query(params?: any) {
//         if (!params) {
//             return this.pmtPartners;
//         }
//         return this.pmtPartners.filter((item) => {
//             for (const key in params) {
//                 if (params.hasOwnProperty(key)) {
//                     const field = item[key];
//                     if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
//                         return item;
//                     } else if (field === params[key]) {
//                         return item;
//                     }
//                 }
//             }
//             return null;
//         });
//     }

//     add(record: PmtPartner) {
//         this.pmtPartners.push(record);
//     }

//     delete(record: PmtPartner) {
//         this.pmtPartners.splice(this.pmtPartners.indexOf(record), 1);
//     }

//     async recordRetrieve(queryString = ''): Promise<ApiResponse> {
//         const url = `${this.env.API_URL}/pmt/pmt-partners${queryString}`;
//         const proRes = this.apiService.getApi(url).pipe(
//           map((res: ApiResponse) => res));
//         return await proRes.toPromise();
//       }

//     async recordCreate(record: PmtPartner): Promise<ApiResponse> {
//         const url = `${this.env.API_URL}/pmt/pmt-partners`;
//         const proRes = this.apiService.postApi(url, record).pipe(
//           map((res: ApiResponse) => {
//             if (res.success && res.payload) {
//               console.log('recordCreate() successful =>', res.payload);
//               let queryString = `?_id=${res.payload.id}`;
//               queryString += `&populate=vehicle,state,county,hub`;
//               this.recordRetrieve(queryString).then(res => {
//                 this.add(res.payload[0])
//               })
//             }
//             return res;
//           }));
//         return await proRes.toPromise();
//     }

//     async recordUpdate(record: PmtPartner, payload): Promise<ApiResponse> {
//         const url = `${this.env.API_URL}/pmt/pmt-partners/${record.id}`;
//         const proRes = this.apiService.updateApi(url, payload).pipe(
//           map((res: ApiResponse) => {
//             if (res.success && res.payload) {
//               this.delete(record);
//               console.log('recordUpdate() successful =>', res.payload);
//               let queryString = `?_id=${res.payload.id}`;
//               queryString += `&populate=vehicle,state,county,hub`;
//               this.recordRetrieve(queryString).then(res => {
//                 this.add(res.payload[0])
//               })
//             }
//             return res;
//           }));
//         return await proRes.toPromise();
//     }

//     async recordPatch(record: PmtPartner): Promise<ApiResponse> {
//         const url = `${this.env.API_URL}/pmt/pmt-partners/${record.id}`;
//         const proRes = this.apiService.patchApi(url, {}).pipe(
//           map((res: ApiResponse) => {
//             this.delete(record);
//             return res;
//           }));
//         return await proRes.toPromise();
//       }

//       async recordDelete(record: PmtPartner): Promise<ApiResponse> {
//         const url = `${this.env.API_URL}/pmt/pmt-partners/${record.id}`;
//         const proRes = this.apiService.deleteApi(url).pipe(
//           map((res: ApiResponse) => {
//             this.delete(record);
//             return res;
//           }));
//         return await proRes.toPromise();
//       }
//     }

import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import {ApiResponse, PmtPartner} from '../../models';
import { ApiService, EnvService } from '../../services';


@Injectable()

export class PmtPartners {
    pmtPartners: PmtPartner[] = [];

    constructor(private apiService: ApiService,
                private env: EnvService) {
        let queryString = `?sort=-surname`;
        queryString += `&populate=vehicle,state,county,hub&limit=300`;
        // this.recordRetrieve(queryString).then(res => { this.pmtPartners = res.payload; console.log(res) });
    }

    query(params?: any) {
        if (!params) {
            return this.pmtPartners;
        }
        return this.pmtPartners.filter((item) => {
            for (const key in params) {
                if (params.hasOwnProperty(key)) {
                    const field = item[key];
                    if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
                        return item;
                    } else if (field === params[key]) {
                        return item;
                    }
                }
            }
            return null;
        });
    }

    add(record: PmtPartner) {
        this.pmtPartners.push(record);
    }

    delete(record: PmtPartner) {
        this.pmtPartners.splice(this.pmtPartners.indexOf(record), 1);
    }

    async recordRetrieve(queryString = ''): Promise<ApiResponse> {
        const url = `${this.env.API_URL}/pmt/pmt-partners${queryString}`;
        const proRes = this.apiService.getApi(url).pipe(
          map((res: ApiResponse) => res));
        return await proRes.toPromise();
      }

    async recordCreate(record: PmtPartner): Promise<ApiResponse> {
        const url = `${this.env.API_URL}/pmt/pmt-partners`;
        const proRes = this.apiService.postApi(url, record).pipe(
          map((res: ApiResponse) => {
            if (res.success && res.payload) {
              console.log('recordCreate() successful =>', res.payload);
              let queryString = `?_id=${res.payload.id}`;
              queryString += `&populate=vehicle,state,county,hub`;
              this.recordRetrieve(queryString).then(res => {
                this.add(res.payload[0])
              })
            }
            return res;
          }));
        return await proRes.toPromise();
    }

    async recordUpdate(record: PmtPartner, payload): Promise<ApiResponse> {
        const url = `${this.env.API_URL}/pmt/pmt-partners/${record.id}`;
        const proRes = this.apiService.updateApi(url, payload).pipe(
          map((res: ApiResponse) => {
            if (res.success && res.payload) {
              this.delete(record);
              console.log('recordUpdate() successful =>', res.payload);
              let queryString = `?_id=${res.payload.id}`;
              queryString += `&populate=vehicle,state,county,hub`;
              this.recordRetrieve(queryString).then(res => {
                this.add(res.payload[0])
              })
            }
            return res;
          }));
        return await proRes.toPromise();
    }

    async recordPatch(record: PmtPartner): Promise<ApiResponse> {
        const url = `${this.env.API_URL}/pmt/pmt-partners/${record.id}`;
        const proRes = this.apiService.patchApi(url, {}).pipe(
          map((res: ApiResponse) => {
            this.delete(record);
            return res;
          }));
        return await proRes.toPromise();
      }

      async recordDelete(record: PmtPartner): Promise<ApiResponse> {
        const url = `${this.env.API_URL}/pmt/pmt-partners/${record.id}`;
        const proRes = this.apiService.deleteApi(url).pipe(
          map((res: ApiResponse) => {
            this.delete(record);
            return res;
          }));
        return await proRes.toPromise();
      }
    }

