import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pml-store-manager',
  templateUrl: './pml-store-manager.component.html',
  styleUrls: ['./pml-store-manager.component.css']
})
export class PmlStoreManagerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
