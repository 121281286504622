import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { Role, ApiResponse, Permission } from '../../models';
import { ApiService, EnvService } from '../../services';


@Injectable()
export class Roles {

  roles: Role[] = [];

  constructor(private apiService: ApiService, private env: EnvService) {
    let queryString = `?sort=-createdAt`;
    queryString += `&populate=permissions`;
    this.recordRetrieve(queryString).then(res => { this.roles = res.payload; console.log(res) });
  }

  query(params?: any) {
    if (!params) {
      return this.roles;
    }
    return this.roles.filter((role) => {
      for (const key in params) {
          if (params.hasOwnProperty(key)) {
            const field = role[key];
            if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
              return role;
            } else if (field === params[key]) {
              return role;
            }
          }
      }
      return null;
    });
  }

  add(record: Role) {
    this.roles.push(record);
  }

  delete(record: Role) {
    this.roles.splice(this.roles.indexOf(record), 1);
  }

  permissionsToString(permissions: Array<Permission> = []): string {
        if (permissions.length > 0) {
            const allPermissions = permissions.reduce((accumulator, currentValue) => {
                return  accumulator + ` ${currentValue.name}`;
              }, '');
              return allPermissions;
        }
        return '-';
    }

    async recordRetrieve(queryString = ''): Promise<ApiResponse> {
      const url = `${this.env.API_URL}/erp/roles${queryString}`;
      const proRes = this.apiService.getApi(url).pipe(
        map((res: ApiResponse) => res));
      return await proRes.toPromise();
    }
    
    async recordCreate(record: Role): Promise<ApiResponse> {
      const url = `${this.env.API_URL}/erp/roles`;
      const proRes = this.apiService.postApi(url, record).pipe(
        map((res: ApiResponse) => {
          if (res.success && res.payload) {
            console.log('recordCreate() successful =>', res.payload);
            let queryString = `?_id=${res.payload.id}`;
            queryString += `&populate=permissions`;
            this.recordRetrieve(queryString).then(res => {
              this.add(res.payload[0])
            })
          }
          return res;
        }));
      return await proRes.toPromise();
  }
  
  async recordUpdate(record: Role, payload): Promise<ApiResponse> {
      const url = `${this.env.API_URL}/erp/roles/${record.id}`;
      const proRes = this.apiService.updateApi(url, payload).pipe(
        map((res: ApiResponse) => {
          if (res.success && res.payload) {
            this.delete(record);
            console.log('recordUpdate() successful =>', res.payload);
            let queryString = `?_id=${res.payload.id}`;
            queryString += `&populate=permissions`;
            this.recordRetrieve(queryString).then(res => {
              this.add(res.payload[0])
            })
          }
          return res;
        }));
      return await proRes.toPromise();
  }

  async recordPatch(record: Role): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/roles/${record.id}`;
    const proRes = this.apiService.patchApi(url, {}).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordDelete(record: Role): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/roles/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }
}