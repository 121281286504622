import {Staff} from '../people';

export class PmlVehicle {
  id: string;
  code: string;
  name: string;
  assignments: any[];
  currentPmlDriver: string | any; // { type: ObjectId, ref: "PmlDriver" },
  assignmentStatus: string;
  description: string;
  engineNumber: string;
  chasisNumber: string;
  plateNumber: string;
  registrationNumber: string;
  vehicleType: string;
  vehicleMake: string;
  vehicleClass: string;
  vehicleCategory: string;
  vehicleLocation: string;
  color: string;
  photo: string;
  recordStatus: string;
  approvedBy: Staff;
  approvedDate: Date;
  remark: string;
  volume: string;
  mass: string;
  driver: any;
  isDto: boolean;
  dtoInitialDeposit: number;
  dtoMaintenance: number;
  dtoRepayment: number;
  ownership: string;
  isHealthy: string;
  isActive: string;
  createdBy: Staff;
  updatedBy: Staff;
  deleted: boolean;
  deletedAt: Date;
  deletedBy: Staff;

  constructor(fields: any) {
    // Quick and dirty extend/assign fields to this model
    for (const f in fields) {
      // @ts-ignore
      this[f] = fields[f];
    }
  }
}

export interface PmlVehicle {
  id: string;
  code: string;
  name: string;
  assignments: any[];
  currentPmlDriver: string | any; // { type: ObjectId, ref: "PmlDriver" },
  assignmentStatus: string;
  description: string;
  engineNumber: string;
  chasisNumber: string;
  plateNumber: string;
  registrationNumber: string;
  vehicleType: string;
  vehicleMake: string;
  vehicleClass: string;
  vehicleCategory: string;
  vehicleLocation: string;
  color: string;
  photo: string;
  recordStatus: string;
  approvedBy: Staff;
  approvedDate: Date;
  remark: string;
  volume: string;
  mass: string;
  driver: any;
  isDto: boolean;
  dtoInitialDeposit: number;
  dtoMaintenance: number;
  dtoRepayment: number;
  ownership: string;
  isHealthy: string;
  isActive: string;
  createdBy: Staff;
  updatedBy: Staff;
  deleted: boolean;
  deletedAt: Date;
  deletedBy: Staff;
}
