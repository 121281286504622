import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { PmtSchedule, PmtRoute, ApiResponse, Staff } from '../../models';
import { ApiService, EnvService, AuthService } from '../../services';
import { DatePipe } from '@angular/common';
import { Vehicles } from '../factory';
import { PmtRoutes } from './pmt-routes';
import { safeGet } from '../../helpers';

@Injectable()
export class PmtSchedules {

  records: Array<PmtSchedule> = [];
  terminalSchedules: Array<PmtSchedule> = [];
  currentSchedule: PmtSchedule;
  today = new Date().toISOString().slice(0, 10);
  user: Staff;
  terminalId = safeGet(this.user.terminal, 'id');

  constructor(private apiService: ApiService,
    private authService: AuthService,
    private datePipe: DatePipe,
    private vehicles: Vehicles,
    private pmtRoutes: PmtRoutes,
    private env: EnvService) {
      this.user = this.authService.getUser();
    //! Default for Terminal
    this.getScheduleByTerminal().then().catch(err => console.log(err));
    //* Default for ERP
    let queryString = `?sort=-createdAt`;
    queryString += `&populate=pmtReservations,pmtBoardings,terminal,vehicle,pmtRoute,partner`;
    queryString += `,boardedBy,loadedBy,arrivedBy,departedBy,waybilledBy,createdBy,updatedBy`;
    this.recordRetrieve(queryString).then(res => { this.records = res.payload; console.log(res) });
  }

  query(params?: any) {
    if (!params) {
      return this.records;
    }
    return this.records.filter((item) => {
      for (const key in params) {
          if (params.hasOwnProperty(key)) {
              const field = item[key];
              if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
                  return item;
              } else if (field === params[key]) {
                  return item;
              }
          }
      }
      return null;
    });
  }

  add(record: PmtSchedule) {
    this.records.push(record);
  }

  delete(record: PmtSchedule) {
    this.records.splice(this.records.indexOf(record), 1);
  }

  async recordRetrieve(queryString = ''): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/pmt/pmt-schedules${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }

  async recordCreate(record: PmtSchedule): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/pmt/pmt-schedules`;
    const proRes = this.apiService.postApi(url, record).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          console.log('recordCreate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=pmtReservations,pmtBoardings,terminal,vehicle,pmtRoute,partner`;
          queryString += `,boardedBy,loadedBy,arrivedBy,departedBy,waybilledBy,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordUpdate(record: PmtSchedule, payload): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/pmt/pmt-schedules/${record.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          console.log('recordUpdate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=pmtReservations,pmtBoardings,terminal,vehicle,pmtRoute,partner`;
          queryString += `,boardedBy,loadedBy,arrivedBy,departedBy,waybilledBy,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordPatch(record: PmtSchedule): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/pmt/pmt-schedules/${record.id}`;
    const proRes = this.apiService.patchApi(url, {}).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordDelete(record: PmtSchedule): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/pmt/pmt-schedules/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }


  //* ==================== OPERATIONS =======================

  getPmtScheduleName(record: PmtSchedule): string {
    try {
      const { boardingDate, pmtRoute, vehicle } = record;
      // @ts-ignore
      const pmtRouteName = pmtRoute ? (pmtRoute.name ? pmtRoute.name : '') : '';
      const vehicleName = vehicle ? (vehicle.name ? vehicle.name : '') : '';
        const dateFormat = boardingDate ? this.datePipe.transform(boardingDate, 'MMM dd, hh:mm a') : '';
      return `PMT ${vehicleName} ${pmtRouteName}  ${dateFormat}`
    } catch(err) {
      console.log('Error :: getScheduleName()' + err.message)
    }
  }

  async getScheduleByTerminal(terminalId = this.terminalId, boardingDate = this.today): Promise<ApiResponse> {
    const str = `boardingDate=${boardingDate}&terminalId=${terminalId}`;
    const url = `${this.env.API_URL}/pmt/pmt-schedules/terminal?${str}`;
    console.log(url);
    const proRes = this.apiService.getApi(url).pipe(
    map((res: ApiResponse) => {
        this.terminalSchedules = res.payload;
        return res;
      }));
      return await proRes.toPromise();
  }

  async recordAdd(payload): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/pmt/pmt-schedules`;
    payload.terminal = this.terminalId;
    const proRes = this.apiService.postApi(url, payload).pipe(
    map((res: ApiResponse) => {
        if (res.success) {
          let schedule = res.payload;
          const vehicle = this.vehicles.query().find(vehicle => vehicle.id === schedule.vehicle);
          const pmtRoute = this.pmtRoutes.query().find(route => route.id === schedule.pmtRoute);
          schedule = {...schedule, ...{vehicle}, ...{pmtRoute}};
          this.add(schedule);
        } else {
          throwError(res.message);
        }
        return res;
      }));
      return await proRes.toPromise();
  }


  async updateOperation(pmtBooking: PmtSchedule, payload): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/pmt/pmt-schedules/operation/${pmtBooking.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
          if (res.success) {
            this.delete(pmtBooking);
            let schedule = res.payload;
            const vehicle = this.vehicles.query().find(vehicle => vehicle.id === schedule.vehicle);
            const pmtRoute = this.pmtRoutes.query().find(route => route.id === schedule.pmtRoute);
            schedule = {...schedule, ...{vehicle}, ...{pmtRoute}};
            this.add(schedule);
          } else {
            throwError(res.message);
          }
          return res;
        }));
        return await proRes.toPromise();
  }


  async busLoadingStatus(pmtBooking: PmtSchedule, payload): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/pmt/pmt-schedules/operation/${pmtBooking.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
          if (res.success) {
            this.delete(pmtBooking);
            let schedule = res.payload;
            const vehicle = this.vehicles.query().find(vehicle => vehicle.id === schedule.vehicle);
            const pmtRoute = this.pmtRoutes.query().find(route => route.id === schedule.pmtRoute);
            schedule = {...schedule, ...{vehicle}, ...{pmtRoute}};
            this.add(schedule);
          } else {
            throwError(res.message);
          }
          return res;
        }));
        return await proRes.toPromise();
  }

}
