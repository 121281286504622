import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { PmlTracking, ApiResponse } from '../../models';
import { ApiService, EnvService } from '../../services';


@Injectable()
export class PmlTrackings {

    pmlTrackings: PmlTracking[] = [];

    constructor(private apiService: ApiService, private env: EnvService) {
        this.recordRetrieve('?sort=-createdAt&limit=1000')
            .then(res => { this.pmlTrackings = res.payload; console.log(res) });
    }

    query(params?: any) {
        if (!params) {
            return this.pmlTrackings;
        }
        return this.pmlTrackings.filter((item) => {
            for (const key in params) {
                if (params.hasOwnProperty(key)) {
                    const field = item[key];
                    if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
                        return item;
                    } else if (field === params[key]) {
                        return item;
                    }
                }
            }
            return null;
        });
    }

    add(record: PmlTracking) {
        this.pmlTrackings.push(record);
    }

    delete(record: PmlTracking) {
        this.pmlTrackings.splice(this.pmlTrackings.indexOf(record), 1);
    }

    async recordRetrieve(queryString = '?sort=-createdAt&limit=1000'): Promise<ApiResponse> {
        const url = `${this.env.API_URL}/pml/pml-trackings${queryString}`;
        const proRes = this.apiService.getApi(url).pipe(
            map((res: ApiResponse) => res));
        return await proRes.toPromise();
    }

    async recordCreate(record: PmlTracking): Promise<ApiResponse> {
        const url = `${this.env.API_URL}/pml/pml-trackings`;
        const proRes = this.apiService.postApi(url, record).pipe(
            map((res: ApiResponse) => {
                if (res.success && res.payload) {
                    this.add(res.payload[0]);
                }
                return res;
            }));
        return await proRes.toPromise();
    }

    async recordUpdate(record: PmlTracking, payload): Promise<ApiResponse> {
        const url = `${this.env.API_URL}/pml/pml-trackings/${record.id}`;
        const proRes = this.apiService.updateApi(url, payload).pipe(
            map((res: ApiResponse) => {
                if (res.success && res.payload) {
                    this.delete(record);
                    this.add(res.payload[0])
                }
                return res;
            }));
        return await proRes.toPromise();
    }

    async recordPatch(record: PmlTracking): Promise<ApiResponse> {
        const url = `${this.env.API_URL}/pml/pml-trackings/${record.id}`;
        const proRes = this.apiService.patchApi(url, {}).pipe(
            map((res: ApiResponse) => {
                this.delete(record);
                return res;
            }));
        return await proRes.toPromise();
    }

    async recordDelete(record: PmlTracking): Promise<ApiResponse> {
        const url = `${this.env.API_URL}/pml/pml-trackings/${record.id}`;
        const proRes = this.apiService.deleteApi(url).pipe(
            map((res: ApiResponse) => {
                this.delete(record);
                return res;
            }));
        return await proRes.toPromise();
    }
}
