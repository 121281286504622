import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { Ticket, ApiResponse } from '../../models';
import { ApiService, EnvService } from '../../services';


@Injectable()
export class Tickets {

  tickets: Ticket[] = [];
  private queryString: string;

  constructor(private apiService: ApiService, private env: EnvService) {
    this.queryString = `?sort=-createdAt`;
    this.queryString += `&populate=staff,customer,createdBy,updatedBy`;
    this.recordRetrieve(this.queryString).then(res => { this.tickets = res.payload; console.log(res) });
  }

  query(params?: any) {
    if (!params) {
      return this.tickets;
    }
    return this.tickets.filter((item) => {
      for (const key in params) {
          if (params.hasOwnProperty(key)) {
            const field = item[key];
            if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
              return item;
            } else if (field === params[key]) {
              return item;
            }
          }
      }
      return null;
    });
  }

  add(record: Ticket) {
    this.tickets.push(record);
  }

  delete(record: Ticket) {
    this.tickets.splice(this.tickets.indexOf(record), 1);
  }

  async recordRetrieve(queryString = ''): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/crm/tickets${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }

  async recordCreate(record: Ticket): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/crm/tickets`;
    const proRes = this.apiService.postApi(url, record).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          console.log('recordCreate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=staff,customer,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
}

async recordUpdate(record: Ticket, payload): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/crm/tickets/${record.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          console.log('recordUpdate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=staff,customer,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
}

async recordPatch(record: Ticket): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/crm/tickets/${record.id}`;
    const proRes = this.apiService.patchApi(url, {}).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

async recordOperation(record: Ticket, payload): Promise<ApiResponse> {
  const url = `${this.env.API_URL}/crm/tickets/operation/${record.id}`;
  const proRes = this.apiService.updateApi(url, payload).pipe(
    map((res: ApiResponse) => {
      this.delete(record);
      return res;
    }));
  return await proRes.toPromise();
}

  async recordDelete(record: Ticket): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/crm/tickets/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }
}
