import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { PmlBillingTier, ApiResponse } from '../../models';
import { ApiService, EnvService } from '../../services';
import { safeGet } from '../../helpers';


@Injectable()
export class PmlBillingTiers {

  pmlBillingTiers: PmlBillingTier[] = [];

  constructor(private apiService: ApiService, private env: EnvService) {
    const queryString = `?sort=name&populate=createdBy,updatedBy`;
    this.recordRetrieve(queryString).then(res => { this.pmlBillingTiers = res.payload; console.log(res) });
  }

  query(params?: any) {
    if (!params) {
      return this.pmlBillingTiers;
    }
    return this.pmlBillingTiers.filter((pmlBilling) => {
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          const field = pmlBilling[key];
          if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
            return pmlBilling;
          } else if (field === params[key]) {
            return pmlBilling;
          }
        }
      }
      return null;
    });
  }


  add(record: PmlBillingTier) {
    this.pmlBillingTiers.push(record);
  }

  delete(record: PmlBillingTier) {
    this.pmlBillingTiers.splice(this.pmlBillingTiers.indexOf(record), 1);
  }

  async recordRetrieve(queryString = ''): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/pml/pml-billing-tiers${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }

  async recordCreate(record: PmlBillingTier): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/pml/pml-billing-tiers`;
    const proRes = this.apiService.postApi(url, record).pipe(
      map((res: ApiResponse) => {
        console.log('recordCreate() successful =>', res.payload);
        this.add(res.payload);
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordUpdate(record: PmlBillingTier, payload): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/pml/pml-billing-tiers/${record.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        this.add(res.payload);
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordPatch(record: PmlBillingTier): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/pml/pml-billing-tiers/${record.id}`;
    const proRes = this.apiService.patchApi(url, {}).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordDelete(record: PmlBillingTier): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/pml/pml-billing-tiers/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }


  getSettings(arrObj: Array<any>, value: string) {
    if (Object.keys(arrObj).length > 1 && arrObj) {
      const Obj = arrObj.find(item => item.name === value);
      return (Obj && safeGet(Obj, 'value')) ? Obj.value : 0;
    }
    return 0;
  }

  booleanToInt(val) {
    if (val === 'true' || val === true) {
      return 1;
    }
    return 0;
  }

  getUrgency(travelHour, departureDate, expectedDate) {
    const diffHours = Math.abs(new Date(departureDate).getTime() - new Date(expectedDate).getTime()) / 3600000;
    return parseFloat((travelHour / diffHours).toFixed(3));
  }

}
