import { Injectable } from '@angular/core';
import { PmlAgent, ApiResponse } from '../../models';
import { ApiService, EnvService } from '../../services';
import { Subject, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class PmlAgents {

    pmlAgents: Array<PmlAgent> = [];

    constructor(private apiService: ApiService, private env: EnvService) {
        let queryString = `?sort=name`;
        queryString += `&populate=manager,county,state,createdBy,updatedBy`;
        this.recordRetrieve(queryString).then(res => { this.pmlAgents = res.payload; console.log(res) });
    }

    query(params?: any) {
        if (!params) {
            return this.pmlAgents;
        }
        return this.pmlAgents.filter((item) => {
            for (const key in params) {
                const field = item[key];
                if (typeof field == 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
                    return item;
                } else if (field == params[key]) {
                    return item;
                }
            }
            return null;
        });
    }

    add(record: PmlAgent) {
        this.pmlAgents.push(record);
    }

    delete(record: PmlAgent) {
        this.pmlAgents.splice(this.pmlAgents.indexOf(record), 1);
    }

    async recordRetrieve(queryString = ''): Promise<ApiResponse> {
        const url = `${this.env.API_URL}/erp/pml-agents${queryString}`;
        const proRes = this.apiService.getApi(url).pipe(
            map((res: ApiResponse) => res));
        return await proRes.toPromise();
    }

    async recordCreate(record: PmlAgent): Promise<ApiResponse> {
        const url = `${this.env.API_URL}/erp/pml-agents`;
        const proRes = this.apiService.postApi(url, record).pipe(
            map((res: ApiResponse) => {
                if (res.success && res.payload) {
                    console.log('recordCreate() successful =>', res.payload);
                    let queryString = `?_id=${res.payload.id}`;
                    queryString += `&populate=region,zone,state,manager,county,state,createdBy,updatedBy`;
                    this.recordRetrieve(queryString).then(res => {
                        this.add(res.payload[0])
                    })
                }
                return res;
            }));
        return await proRes.toPromise();
    }

    async recordUpdate(record: PmlAgent, payload): Promise<ApiResponse> {
        const url = `${this.env.API_URL}/erp/pml-agents/${record.id}`;
        const proRes = this.apiService.updateApi(url, payload).pipe(
            map((res: ApiResponse) => {
                if (res.success && res.payload) {
                    this.delete(record);
                    console.log('recordUpdate() successful =>', res.payload);
                    let queryString = `?_id=${res.payload.id}`;
                    queryString += `&populate=region,zone,state,manager,county,state,createdBy,updatedBy`;
                    this.recordRetrieve(queryString).then(res => {
                        this.add(res.payload[0])
                    })
                }
                return res;
            }));
        return await proRes.toPromise();
    }

    async recordPatch(record: PmlAgent): Promise<ApiResponse> {
        const url = `${this.env.API_URL}/erp/pml-agents/${record.id}`;
        const proRes = this.apiService.patchApi(url, { deleted: true }).pipe(
            map((res: ApiResponse) => {
                this.delete(record);
                return res;
            }));
        return await proRes.toPromise();
    }

    async recordDelete(record: PmlAgent): Promise<ApiResponse> {
        const url = `${this.env.API_URL}/erp/pml-agents/${record.id}`;
        const proRes = this.apiService.deleteApi(url).pipe(
            map((res: ApiResponse) => {
                this.delete(record);
                return res;
            }));
        return await proRes.toPromise();
    }
}
