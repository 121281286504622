import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import * as printJS from 'print-js';
import { PmlParcel } from '../../models';
import { getFullname } from '../../helpers';

export interface ReportObject {
  reportType: ReportType;
  reportData: PmlParcel;
  date?: Date;
}

export enum ReportType {
  DURATION = 'Duration',
  REPORT = 'Report',
  DETAIL = 'Detail'
}

@Component({
  selector: 'app-parcel-print-sheet',
  templateUrl: './parcel-print-sheet.component.html',
  styleUrls: ['./parcel-print-sheet.component.css']
})
export class ParcelPrintSheetComponent implements OnInit {

  @ViewChild('printArea', null)  printArea: ElementRef;
  show: boolean = false;
  data: PmlParcel;
  getFullname = getFullname;

  constructor() { }

  ngOnInit(): void {
  }

  print(data: ReportObject): void {
    this.data = data.reportData;
    this.show = true;
    setTimeout(
      () => {
        printJS({
          printable: this.printArea.nativeElement.outerHTML,
          type: 'raw-html',
          documentTitle: `Pml Parcel detail for parcel code.pdf`,
        });
        this.show = false;
      }, 0
    );
  }

}
