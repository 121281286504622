import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { Permission, ApiResponse } from '../../models';
import { ApiService, EnvService } from '../../services';


@Injectable()
export class Permissions {

  permissions: Permission[] = [];

  constructor(private apiService: ApiService, private env: EnvService) {
    let queryString = `?sort=-createdAt`;
    queryString += `&populate=table`;
    this.recordRetrieve(queryString).then(res => { this.permissions = res.payload; console.log(res) });
  }

  query(params?: any) {
    if (!params) {
      return this.permissions;
    }
    return this.permissions.filter((permission) => {
      for (const key in params) {
          if (params.hasOwnProperty(key)) {
            const field = permission[key];
            if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
              return permission;
            } else if (field === params[key]) {
              return permission;
            }
          }
      }
      return null;
    });
  }

  add(record: Permission) {
    this.permissions.push(record);
  }

  delete(record: Permission) {
    this.permissions.splice(this.permissions.indexOf(record), 1);
  }

  async recordRetrieve(queryString = ''): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/permissions${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }

  async recordCreate(record: Permission): Promise<ApiResponse> {
      const url = `${this.env.API_URL}/erp/permissions`;
      const proRes = this.apiService.postApi(url, record).pipe(
          map((res: ApiResponse) => {
            console.log('recordCreate() successful =>', res.payload);
            this.add(res.payload);
            return res;
          }));
      return await proRes.toPromise();
  }

  async recordUpdate(record: Permission, payload): Promise<ApiResponse> {
      const url = `${this.env.API_URL}/erp/permissions/${record.id}`;
      const proRes = this.apiService.updateApi(url, payload).pipe(
          map((res: ApiResponse) => {
            this.delete(record);
            this.add(res.payload);
            return res;
          }));
      return await proRes.toPromise();
  }

  async recordPatch(record: Permission): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/permissions/${record.id}`;
    const proRes = this.apiService.patchApi(url, {}).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordDelete(record: Permission): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/permissions/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }
}