import { Vehicle } from '../factory/vehicle';
import { Office } from './office';
import { Terminal } from '../location/terminal';
import { Rating } from '../crm/rating';
import { Role } from './role';
import { County } from '../location/county';
import { State } from '../location/state';
import { Bank } from '../finance/bank';
import { PmlAssignment } from '../pml';

export class Staff {
    id: string;
    serial?: string;
    category?: string;
    title?: string;
    surname: string;
    otherName: string;
    gender: string;
    birthDate: Date;
    maritalStatus: string;
    children?: number;
    phone?: string;
    phoneHome?: string;
    address?: string;
    village?: string;
    state: State;
    county: County;
    country?: string;
    email?: string;
    emailPersonal?: string;
    employment?: string;
    coverage?: string;
    password?: string;
    assignments?: PmlAssignment[];
    otp?: string;
    otpCount?: number;
    kin: string;
    kinPhone: string;
    kinAddress: string;
    guarantor1: string;
    guarantor1Phone: string;
    guarantor1Address: string;
    guarantor2?: string;
    guarantor2Phone?: string;
    guarantor2Address?: string;
    profession?: string;
    qualification?: string;
    institution?: string;
    employmentStatus: string;
    tax?: number;
    annualIncome?: number;
    basicSalary?: number;
    bonus?: number;
    entertainmentAllowance?: number;
    houseAllowance?: number;
    lunchAllowance?: number;
    medicalAllowance?: number;
    transportAllowance?: number;
    utilityAllowance?: number;
    welfareAllowance?: number;
    pension?: number;
    assurance?: number;
    bank?: Bank;
    bankAccountNumber?: string;
    bankAccountName?: string;
    rank?: string;
    office: Office;
    role: Role;
    superior: Staff;
    subsidiary?: string;
    terminal: Terminal;
    vehicle?: Vehicle;
    notice?: string;
    status?: string;
    ratings?: Array<Rating>;
    remark?: string;
    photo?: string;
    isSalaryPayable?: boolean;
    isDocumentComplete?: boolean;
    accessLevel?: string;
    approvedBy?: Staff;
    approvedDate?: Date;
    disengagedBy?: Staff;
    disengagedDate?: Date;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}

export interface Staff {
    id: string;
    serial?: string;
    category?: string;
    title?: string;
    surname: string;
    otherName: string;
    gender: string;
    birthDate: Date;
    maritalStatus: string;
    children?: number;
    phone?: string;
    phoneHome?: string;
    address?: string;
    village?: string;
    state: State;
    county: County;
    country?: string;
    email?: string;
    emailPersonal?: string;
    employment?: string;
    coverage?: string;
    password?: string;
    assignments?: PmlAssignment[];
    otp?: string;
    otpCount?: number;
    kin: string;
    kinPhone: string;
    kinAddress: string;
    guarantor1: string;
    guarantor1Phone: string;
    guarantor1Address: string;
    guarantor2?: string;
    guarantor2Phone?: string;
    guarantor2Address?: string;
    profession?: string;
    qualification?: string;
    institution?: string;
    employmentStatus: string;
    tax?: number;
    annualIncome?: number;
    basicSalary?: number;
    bonus?: number;
    entertainmentAllowance?: number;
    houseAllowance?: number;
    lunchAllowance?: number;
    medicalAllowance?: number;
    transportAllowance?: number;
    utilityAllowance?: number;
    welfareAllowance?: number;
    pension?: number;
    assurance?: number;
    bank?: Bank;
    bankAccountNumber?: string;
    bankAccountName?: string;
    rank?: string;
    office: Office;
    role: Role;
    superior: Staff;
    subsidiary?: string;
    terminal: Terminal;
    vehicle?: Vehicle;
    notice?: string;
    status?: string;
    ratings?: Array<Rating>;
    remark?: string;
    photo?: string;
    isSalaryPayable?: boolean;
    isDocumentComplete?: boolean;
    accessLevel?: string;
    approvedBy?: Staff;
    approvedDate?: Date;
    disengagedBy?: Staff;
    disengagedDate?: Date;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

}
