import { PmlParcel } from "."
import { Staff, State, Terminal } from ".."

export interface PmlAssignment {
    id?: string;
    _id?: string;
    type: 'CT' | 'TC' | 'CC';
    code: string;
    trxref: string;
    senderType: any; // users Enum
    recipientType: any; // users Enum
    sender: Staff;
    recipient: Staff;
    senderPhone: string;
    senderEmail?: string;
    senderName?: string;
    recipientPhone?: string;
    recipientEmail?: string;
    recipientName?: string;
    description: string;
    terminalFrom: Terminal;
    terminalTo: Terminal;
    locationFrom: {address: string, id?: string, type?: string, coordinates: number[]};
    locationTo: {address: string, id?: string, type?: string, coordinates: number[]};
    stateFrom: State;
    stateTo: State;
    distance?: number;
    returnAddress?: string;
    confirmation?: string;
    pmlParcel?: PmlParcel;
    amount?: number;
    paymentMethod: string;
    paymentGateway: string;
    paymentStatus: string;
    gateway: {} | any;
    vehicleType?: string;  // vehicle enum
    vehicleSerial?: string;
    dispatcher: Staff;
    status: string; // status enum
    acceptedBy?: Staff;
    acceptedAt?: Date;
    collectedBy?: Staff;
    collectedAt?: Date;
    cancelledBy?: Staff;
    cancelledAt?: Date;
    cancelledType: string;
    dispatchedBy?: Staff;
    dispactchedAt?: Date;
    confirmedBy?: Staff;
    confirmedAt?: Date;
    confirmedType?: string;
    deliveredBy?: Staff;
    deliveredAt?: Date;

    remark: string;
    createdBy: Staff;
    updatedBy: Staff;
    deleted: boolean;
    deletedBy: Staff;
    deletedAt: Date;
    erp?: number;

}

export class PmlAssignment {
    id?: string;
    _id?: string;
    type: 'CT' | 'TC' | 'CC';
    code: string;
    trxref: string;
    senderType: any; // users Enum
    recipientType: any; // users Enum
    sender: Staff;
    recipient: Staff;
    senderPhone: string;
    senderEmail?: string;
    senderName?: string;
    recipientPhone?: string;
    recipientEmail?: string;
    recipientName?: string;
    description: string;
    terminalFrom: Terminal;
    terminalTo: Terminal;
    locationFrom: {address: string, id?: string, type?: string, coordinates: number[]};
    locationTo: {address: string, id?: string, type?: string, coordinates: number[]};
    stateFrom: State;
    stateTo: State;
    distance?: number;
    returnAddress?: string;
    confirmation?: string;
    pmlParcel?: PmlParcel;
    amount?: number;
    paymentMethod: string;
    paymentGateway: string;
    paymentStatus: string;
    gateway: {} | any;
    vehicleType?: string;  // vehicle enum
    vehicleSerial?: string;
    dispatcher: Staff;
    status: string; // status enum
    acceptedBy?: Staff;
    acceptedAt?: Date;
    collectedBy?: Staff;
    collectedAt?: Date;
    cancelledBy?: Staff;
    cancelledAt?: Date;
    cancelledType: string;
    dispatchedBy?: Staff;
    dispactchedAt?: Date;
    confirmedBy?: Staff;
    confirmedAt?: Date;
    confirmedType?: string;
    deliveredBy?: Staff;
    deliveredAt?: Date;

    remark: string;
    createdBy: Staff;
    updatedBy: Staff;
    deleted: boolean;
    deletedBy: Staff;
    deletedAt: Date;
    erp?: number;

    constructor(fields:any){
        for(let f of fields){
            this[f] = fields[f];
        }
    }
}

// acceptedBy: { type: ObjectId, ref: "Staff" },
// acceptedAt: { type: Date },

// collectedBy: { type: ObjectId },
// collectedAt: { type: Date },

// cancelledBy: { type: ObjectId },
// cancelledAt: { type: Date },
// cancelledType: { type: String, enum: USERS },

// dispatchedBy: { type: ObjectId, ref: "Staff" },
// dispatchedAt: { type: Date },

// confirmedBy: { type: ObjectId },
// confirmedAt: { type: Date },
// confirmedType: { type: String, enum: USERS },

// deliveredBy: { type: ObjectId, ref: "Staff" },
// deliveredAt: { type: Date },

// remark: { type: String, trim: true },
// createdBy: { type: ObjectId },
// updatedBy: { type: ObjectId, ref: "Staff" },
// deleted: { type: Boolean, default: false, select: false },
// deletedAt: { type: Date },
// deletedBy: { type: ObjectId, ref: "Staff" },
// erp: { type: Number, default: DATABASE.ERP_VERSION, select: false },



// //
// type: { type: String, enum: TYPE, required: true },
// code: { type: String, trim: true, required: true },
// trxref: { type: String, maxlength: 50, required: true },
// senderType: { type: String, enum: USERS, required: true },
// sender: { type: ObjectId, required: true },
// recipientType: { type: String, enum: USERS, required: true },
// recipient: { type: ObjectId },
// senderPhone: { type: String, trim: true, required: true },
// senderEmail: { type: String, trim: true },
// senderName: { type: String, trim: true },
// recipientPhone: { type: String, trim: true },
// recipientEmail: { type: String, trim: true },
// recipientName: { type: String, trim: true },

// description: { type: String },
// terminalFrom: { type: ObjectId, ref: "Terminal" },
// terminalTo: { type: ObjectId, ref: "Terminal" },
// locationFrom: { type: Location, required: true },
// locationTo: { type: Location, required: true },
// stateFrom: { type: ObjectId, ref: "State", required: true },
// stateTo: { type: ObjectId, ref: "State", required: true },
// distance: { type: Number, min: 0 },
// returnAddress: { type: String },
// confirmation: { type: String, trim: true, select: false },

// pmlParcel: { type: ObjectId, ref: "PmlParcel" },
// amount: { type: Number, min: 0, default: 0 },
// paymentMethod: { type: String, enum: Object.values(PAYMENT.METHOD) },
// paymentGateway: { type: String, enum: Object.values(PAYMENT.GATEWAY) },
// paymentStatus: {
//   type: String,
//   enum: Object.values(PAYMENT.STATUS),
//   required: true,
//   default: PAYMENT.STATUS.PENDING,
// },
// gateway: { type: Mixed, required: false },
// // gateway: {
// //   id: { type: String },
// //   amount: { type: String },
// //   accountId: { type: String },
// //   customerId: { type: String },
// //   currency: { type: String },
// //   date: { type: Date },
// // },
// vehicleType: { type: String, trim: true, enum: VEHICLE_TYPE },
// vehicleSerial: { type: String, trim: true },
// dispatcher: { type: ObjectId, ref: "Staff", required: true },
// status: {
//   type: String,
//   enum: STATUS,
//   default: PICKUP_STATUS.PENDING,
// },