import { Routes } from "@angular/router";

import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { LoginComponent } from "./pages/login/login.component";
import { LoginOtpComponent } from "./pages/login-otp/login-otp.component";
import { ForgotPasswordComponent } from "./pages/forgot-password/forgot-password.component";
import { AuthGuard } from "./services";
import { EcommerceManagerComponent } from "./pages/ecommerce/ecommerce-manager/ecommerce-manager.component";
import { PmlStoreManagerComponent } from "./pages/pml-store/pml-store-manager/pml-store-manager.component";
import { AnalyticsComponent } from "./pages/analytics/analytics.component";
import { PmlDispatcherManagerComponent } from "./pages/pml-dispatcher/pml-dispatcher-manager/pml-dispatcher-manager.component";
import { AssignmentManagerComponent } from "./pages/pml-assignment/assignment-manager/assignment-manager.component";

export const AppRoutes: Routes = [
  {
    path: "",
    redirectTo: "dashboard",
    pathMatch: "full",
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "login-otp",
    component: LoginOtpComponent,
  },
  {
    path: "forgot-password",
    component: ForgotPasswordComponent,
  },
  {
    path: "",
    component: AdminLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: "./pages/dashboard/dashboard.module#DashboardModule",
      },
      {
        path: "analytics/analytic-report",
        loadChildren: "./pages/analytics/analytics.module#AnalyticsModule",
      },

      {
        path: "analytics/report",
        loadChildren: "./pages/report/report.module#ReportModule",
      },
      {
        path: "analytics/bulk-report",
        loadChildren: "./pages/bulk-report/bulk-report.module#BulkReportModule",
      },
      {
        path: "customers",
        loadChildren: "./pages/customer/customer.module#CustomerModule",
      },
      {
        path: "billing",
        loadChildren: "./pages/billing/billing.module#BillingModule",
      },
      {
        path: "pml-parcels",
        loadChildren: "./pages/pml-parcels/pml-parcels.module#PmlParcelsModule",
      },
      {
        path: "pml-packages",
        loadChildren:
          "./pages/pml-packages/pml-packages.module#PmlPackagesModule",
      },
      {
        path: "pml-assignment",
        component: AssignmentManagerComponent,
        children: [
          {
            path: "",
            loadChildren:
              "./pages/pml-assignment/pml-assignment.module#PmlAssignmentModule",
          },
        ],
      },
      {
        path: "pml-dispatcher",
        component: PmlDispatcherManagerComponent,
        children: [
          {
            path: "",
            loadChildren:
              "./pages/pml-dispatcher/pml-dispatcher.module#PmlDispatcherModule",
          },
        ],
      },
      {
        path: "pml-shipments",
        loadChildren:
          "./pages/pml-shipments/pml-shipments.module#PmlShipmentsModule",
      },
      {
        path: "pml-locations",
        loadChildren:
          "./pages/pml-locations/pml-locations.module#PmlLocationsModule",
      },
      {
        path: "ecommerce",
        component: EcommerceManagerComponent,
        children: [
          {
            path: "",
            loadChildren: "./pages/ecommerce/ecommerce.module#EcommerceModule",
          },
        ],
      },
      {
        path: "pml-impounds",
        loadChildren: "./pages/pml-impound/pml-impound.module#PmlImpoundModule",
      },
      {
        path: "pml-agents",
        loadChildren: "./pages/pml-agents/pml-agents.module#PmlAgentsModule",
      },
      {
        path: "pml-vehicles",
        loadChildren:
          "./pages/pml-vehicles/pml-vehicles.module#PmlVehiclesModule",
      },
      {
        path: "pml-store",
        component: PmlStoreManagerComponent,
        children: [
          {
            path: "",
            loadChildren: "./pages/pml-store/pml-store.module#PmlStoreModule",
          },
        ],
      },
      {
        path: "components",
        loadChildren:
          "./pages/components/components.module#ComponentsPageModule",
      },
      {
        path: "forms",
        loadChildren: "./pages/forms/forms.module#Forms",
      },
      {
        path: "tables",
        loadChildren: "./pages/tables/tables.module#TablesModule",
      },
      {
        path: "maps",
        loadChildren: "./pages/maps/maps.module#MapsModule",
      },
      {
        path: "widgets",
        loadChildren: "./pages/widgets/widgets.module#WidgetsModule",
      },
      {
        path: "charts",
        loadChildren: "./pages/charts/charts.module#ChartsModule",
      },
      {
        path: "calendar",
        loadChildren: "./pages/calendar/calendar.module#CalendarModulee",
      },
      {
        path: "user-profile",
        loadChildren:
          "./pages/user-profile/user-profile.module#UserProfileModule",
      },
      {
        path: "",
        loadChildren:
          "./pages/pages/user-profile/user-profile.module#UserModule",
      },
      {
        path: "",
        loadChildren: "./pages/pages/timeline/timeline.module#TimelineModule",
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "pages",
        loadChildren: "./pages/pages/pages.module#PagesModule",
      },
    ],
  },
];
