import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { Asset, ApiResponse } from '../../models';
import { ApiService, EnvService } from '../../services';


@Injectable()
export class Assets {

  assets: Asset[] = [];

  constructor(private apiService: ApiService, private env: EnvService) {
    let queryString = `?sort=-createdAt`;
    queryString += `&populate=assignments,staff,partner,category,terminal,purchaseOrder,createdBy,updatedBy`;
    this.recordRetrieve(queryString).then(res => { this.assets = res.payload; console.log(res) });
  }

  query(params?: any) {
    if (!params) {
      return this.assets;
    }
    return this.assets.filter((item) => {
      for (const key in params) {
          if (params.hasOwnProperty(key)) {
            const field = item[key];
            if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
              return item;
            } else if (field === params[key]) {
              return item;
            }
          }
      }
      return null;
    });
  }

  add(record: Asset) {
    this.assets.push(record);
  }

  delete(record: Asset) {
    const index = this.assets.findIndex(asset => asset.id === record.id);
    this.assets.splice(index, 1);
  }

  async recordRetrieve(queryString = ''): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/assets${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }

  async recordCreate(record: Asset): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/assets`;
    const proRes = this.apiService.postApi(url, record).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          console.log('recordCreate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=assignments,staff,partner,category,terminal,purchaseOrder,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordUpdate(record: Asset, payload): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/assets/${record.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          console.log('recordUpdate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=assignments,staff,partner,category,terminal,purchaseOrder,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordPatch(record: Asset): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/assets/${record.id}`;
    const proRes = this.apiService.patchApi(url, {}).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordDelete(record: Asset): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/assets/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }
}
