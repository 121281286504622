import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { PmlVehicle, ApiResponse } from '../../models';
import { ApiService, EnvService } from '../../services';


@Injectable()
export class PmlVehicles {

  pmlVehicles: PmlVehicle[] = [];

  constructor(private apiService: ApiService, private env: EnvService) {
    this.recordRetrieve('?limit=100')
      .then(res => { this.pmlVehicles = res.payload; console.log(res); });
  }

  query(params?: any) {
    if (!params) {
      return this.pmlVehicles;
    }
    return this.pmlVehicles.filter((item) => {
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          const field = item[key];
          if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
            return item;
          } else if (field === params[key]) {
            return item;
          }
        }
      }
      return null;
    });
  }

  add(record: PmlVehicle) {
    this.pmlVehicles.push(record);
  }

  delete(record: PmlVehicle) {
    this.pmlVehicles.splice(this.pmlVehicles.indexOf(record), 1);
  }

  async recordRetrieve(queryString = '?limit=100'): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/vehicles${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }

  async recordCreate(record: PmlVehicle): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/vehicles`;
    const proRes = this.apiService.postApi(url, record).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.add(res.payload[0]);
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordUpdate(record: PmlVehicle, payload): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/vehicles/${record.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          this.add(res.payload[0]);
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordPatch(record: PmlVehicle): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/vehicles/${record.id}`;
    const proRes = this.apiService.patchApi(url, {}).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordDelete(record: PmlVehicle): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/vehicles/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }
}
