import { Customer } from '../crm/customer';
import { Terminal } from '../location/terminal';
import { City } from '../location/city';
import {State} from '../location';
import {PmlParcel} from './pml-parcel';
import {Staff} from '../people';
import {Assignment} from '../finance';

export class PmlPickup {
  id: string;
  name: string;
  senderType: string;
  sender: Customer;
  recipientType: string;
  recipient: Customer;
  senderPhone: string;
  senderEmail: string;
  recipientPhone: string;
  recipientEmail: string;
  description: string;
  terminalFrom: Terminal;
  terminalTo: Terminal;
  locationFrom: Terminal;
  locationTo: Terminal;
  stateFrom: State;
  stateTo: State;
  distance: number;
  deliveryAddress: string;
  returnAddress: string;
  confirmation: string;
  pmlParcels: PmlParcel[];
  amount: number;
  paymentMethod: string;
  paymentGateway: string;
  paymentStatus: string;
  gateway: string;
  vehicleType: string;
  vehicleSerial: string;
  driver: Staff;
  staff: Staff;
  agent: Staff;
  status: string;
  collectedBy: Staff;
  collectedAt: Date;

  cancelledBy: Staff;
  cancelledAt: Date;
  cancelledType: string;

  dispatchedBy: Staff;
  dispatchedAt: Date;

  confirmedBy: Staff;
  confirmedAt: Date;
  confirmedType: string;

  deliveredBy: Staff;
  deliveredAt: Date;

  declinedAt: Date;
  declinedBy: Staff;

  assignment: Assignment;

  remark: string;
  updatedBy: string;

  constructor(fields: any) {
    // Quick and dirty extend/assign fields to this model
    for (const f in fields) {
      // @ts-ignore
      this[f] = fields[f];
    }
  }
}
