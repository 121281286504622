import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse, AccidentCause } from '../../models';
import { ApiService, EnvService } from '../../services';


@Injectable()
export class AccidentCauses {

  accidentCauses: AccidentCause[] = [];

  constructor(private apiService: ApiService, private env: EnvService) {
    let queryString = `?sort=name`;
    queryString += `&populate=createdBy,updatedBy`;
    this.recordRetrieve(queryString).then(res => { this.accidentCauses = res.payload; console.log(res) });
  }

  query(params?: any) {
    if (!params) {
      return this.accidentCauses;
    }
    return this.accidentCauses.filter((item) => {
      for (const key in params) {
          if (params.hasOwnProperty(key)) {
            const field = item[key];
            if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
              return item;
            } else if (field === params[key]) {
              return item;
            }
          }
      }
      return null;
    });
  }

  add(record: AccidentCause) {
    this.accidentCauses.push(record);
  }

  delete(record: AccidentCause) {
    this.accidentCauses.splice(this.accidentCauses.indexOf(record), 1);
  }

  async recordRetrieve(queryString = ''): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/accident-causes${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }

  async recordCreate(record: AccidentCause): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/accident-causes`;
    const proRes = this.apiService.postApi(url, record).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          console.log('recordCreate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=createdBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
}

async recordUpdate(record: AccidentCause, payload): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/accident-causes/${record.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          console.log('recordUpdate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=createdBy,updatedBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
}

async recordPatch(record: AccidentCause): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/accident-causes/${record.id}`;
    const proRes = this.apiService.patchApi(url, {}).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordDelete(record: AccidentCause): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/accident-causes/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }
}