import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PmlPackages, PmlParcels, PmlShipments} from '../../providers';
import {PmlPackage, PmlParcel, PmlShipment} from '../../models';
import {ComponentPortal} from '@angular/cdk/portal';
import {LoadingComponent} from '../loading/loading.component';
import {Overlay} from '@angular/cdk/overlay';

@Component({
  selector: 'app-packages-display',
  templateUrl: './packages-display.component.html',
  styleUrls: ['./packages-display.component.css']
})
export class PackagesDisplayComponent implements OnInit {
  @Input() inputType: 'PARCEL'|'PACKAGE';
  @Input() shipment: PmlShipment;
  @Input() package: PmlPackage;
  @Input() packages: [] = [];
  @Input() parcels: [] = [];
  availableParcels: PmlParcel[] = [];
  availablePackages: PmlPackage[] = [];
  @Input() displayOptions: boolean;
  @Output() response: EventEmitter<string> = new EventEmitter<string>();

  overlayReference: any;

  constructor(
              private pmlParcels: PmlParcels,
              private pmlPackages: PmlPackages,
              private overlay: Overlay) { }

  ngOnInit() {
    console.log('Input type => ', this.inputType);
    console.log('Parcels => ', this.parcels);
    console.log('Packages => ', this.packages);
    this.inputType == 'PARCEL' ? this.getAllParcels() : this.getAllPackages();
    this.showOverlay();
  }

  // acceptPackage(pmlPackage: PmlPackage, index) {
  //   const payload = {
  //     removedPmlPackages: [pmlPackage.id]
  //   };
  //   console.log('Packages to send', payload);
  //   this.pmlShipments.operatePmlShipment(this.shipment, payload).then(res => {
  //     if (res.success) {
  //       this.packages.splice(index, 1);
  //       this.response.emit(res.message);
  //     }
  //   }).catch(err => {
  //     this.response.emit(err);
  //   });
  // }

  // acceptAllPackages() {
  //   const payload = {
  //     removedPmlPackages: this.availablePackages.map(item => item.id),
  //   };
  //   console.log('Payload to submit => ', payload);
  //   this.pmlShipments.operatePmlShipment(this.shipment, payload).then(res => {
  //     this.response.emit(res.message);
  //   }).catch(err => {
  //     this.response.emit(err);
  //   });
  // }

  acceptAllParcels() {
    const payload = {
      pmlParcelCodes: this.availableParcels.map(item => item.code),
    };
    console.log('Parcels => ', payload);
    this.pmlParcels.setArrivedParcels(payload).then(res => {
      // remove package from the list
      this.parcels = [];
      this.response.emit(res.message);
    }).catch(err => {
      this.response.emit(err);
    });
  }

  acceptParcel(parcel, index) {
    console.log('Index to arrive => ', index);
    console.log('Parcel to arrive', parcel);
    const payload = {
      pmlParcelCodes: [parcel.code]
    };
    console.log('Payload being sent => ', payload);
    this.pmlParcels.setArrivedParcels(payload).then(res => {
      // remove package from the list
      this.parcels.splice(index, 1);
      this.response.emit(res.message);
    }).catch(err => {
      this.response.emit(err);
    });
  }

  getAllParcels() {
    console.log('In get All parcels', this.parcels);
    // get all packages and their details here
    Promise.all(this.parcels.map(i => this.getParcel(i))).then(res => {
      console.log('Response from manifest promise => ', res);
      this.availableParcels = res;
    }).catch(err => {
      console.log('Promise all failed => ', err);
      // this.showNotification(err);
      return;
    }).finally(() => {
      this.hideOverlay();
    });
  }

  getAllPackages() {
    Promise.all(this.packages.map(i => this.getPackages(i))).then(res => {
      console.log('Response from manifest promise => ', res);
      this.availablePackages = res;
    }).catch(err => {
      console.log('Promise all failed => ', err);
      // this.showNotification(err);
      return;
    }).finally(() => {
      this.hideOverlay();
    });
  }

  getParcel(parcel): Promise<PmlParcel> {
    console.log('in parcel get method', parcel);
    return new Promise((resolve, reject) => {
      this.pmlParcels.recordRetrieve(`?_id=${parcel}&populate=terminalTo,pmlPackage`).then(res => {
        if (res.success) {
          resolve(res.payload[0]);
        } else {
          reject(res.message);
        }
      }).catch(err => {
        reject(err);
      });
    });
  }

  getPackages(packages): Promise<PmlPackage> {
    console.log('in parcel get method', packages);
    return new Promise((resolve, reject) => {
      this.pmlPackages.recordRetrieve(`?_id=${packages}&populate=pmlParcels.terminalTo`).then(res => {
        if (res.success) {
          resolve(res.payload[0]);
        } else {
          reject(res.message);
        }
      }).catch(err => {
        reject(err);
      });
    });
  }

  flatDeep(arr, d = 1) {
    return d > 0 ? arr.reduce((acc, val) => acc.concat(Array.isArray(val) ? this.flatDeep(val, d - 1) : val), []) : arr.slice();
  }

  showOverlay() {
    this.overlayReference = this.overlay.create({
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
      hasBackdrop: true
    });
    this.overlayReference.attach(new ComponentPortal(LoadingComponent));
  }

  hideOverlay() {
    this.overlayReference.detach();
  }

}
