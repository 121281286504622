import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse} from '../../models';
import { EnvService, ApiService} from '../../services';

@Injectable({
    providedIn: 'root'
})

export class PmlReports {
    constructor(
        private env: EnvService,
        private apiService: ApiService
    ){

    }

    recordCreate(record: any): Observable<ApiResponse> {
        const url = `${this.env.API_URL}/pml/reports/generate`;
        const proRes = this.apiService.postApi(url, record).pipe(
            map((res: ApiResponse) => {
                return res;
            }));
        // return await proRes.toPromise();
        return proRes
    }

}