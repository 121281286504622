import { Staff } from '../people/staff';
import { PmlShipment } from './pml-shipment';
import { PmlRouting } from './pml-routing';
import { Partner } from '../people/partner';
import {PmlParcel} from './pml-parcel';
import {PmlTerminal} from './pml-terminal';

export class PmlPackage {
    id: string;
    code: string;
    name: string;
    label: string;
    parcelCount: number;
    recentCustodian: 'PARTNER'|'STAFF';
    recentPartner: Partner;
    recentStaff: Staff;
    packaged: boolean;
    pmlParcels: PmlParcel[];
    pmlShipments: PmlShipment[];
    pmlRoutings: PmlRouting[];
    transportCharge: Number;
    deliveryStatus: DeliveryStatus;
    terminalFrom: PmlTerminal;
    terminalStore: PmlTerminal;
    terminalTo: PmlTerminal;
    status: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        Object.keys(fields).forEach(key => {
            this[key] = fields[key];
        });
    }

}

enum DeliveryStatus {
    PENDING = 'PENDING',
    STORED = 'STORED',
    TRANSIT = 'TRANSIT',
    DELIVERED = 'DELIVERED',
    DISCHARGED = 'DISCHARGED',
}
