import { Terminal } from '../location/terminal';
import { Vehicle } from '../factory/vehicle';
import { Staff } from '../people/staff';
import { Customer } from '../crm/customer';
import { Category } from '../general';
import { Partner } from '../people';
import { County } from '../location';



export class PmtHiring {
    id: string;
    code: string;
    type: 'TERMINAL_TERMINAL'|'HOME_HOME'|'TERMINAL_HOME'|'HOME_TERMINAL';
    tripType: 'ONE_WAY'|'TWO_WAY';
    category: Category;
    cashier?: Staff;
    partner?: Partner;
    vehicle: Vehicle;
    vehicleType: string;
    customer: Customer;
    terminalFrom: Terminal;
    terminalTo: Terminal;
    paymentStatus: string;
    paymentMethod: string;
    paymentGateway: string;
    paymentDate?: Date;
    countyFrom: County;
    countyTo: County;
    longitudeFrom: number;
    longitudeTo: number;
    latitudeFrom: number;
    latitudeTo: number;
    tripDuration: number; // days
    departureDate: Date;
    returnDate: Date;
    estimatedCharge: number;
    actualCharge: number;
    status: 'PENDING'|'PAID'|'SCHEDULED'|'FULFILLED'|'CLOSED';
    description: string;
    createdBy?: Staff | Customer;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}

export interface PmtHiring {
    id: string;
    code: string;
    type: 'TERMINAL_TERMINAL'|'HOME_HOME'|'TERMINAL_HOME'|'HOME_TERMINAL';
    tripType: 'ONE_WAY'|'TWO_WAY';
    category: Category;
    cashier?: Staff;
    partner?: Partner;
    vehicle: Vehicle;
    vehicleType: string;
    customer: Customer;
    terminalFrom: Terminal;
    terminalTo: Terminal;
    paymentStatus: string;
    paymentMethod: string;
    paymentGateway: string;
    paymentDate?: Date;
    countyFrom: County;
    countyTo: County;
    longitudeFrom: number;
    longitudeTo: number;
    latitudeFrom: number;
    latitudeTo: number;
    tripDuration: number; // days
    departureDate: Date;
    returnDate: Date;
    estimatedCharge: number;
    actualCharge: number;
    status: 'PENDING'|'PAID'|'SCHEDULED'|'FULFILLED'|'CLOSED';
    description: string;
    createdBy?: Staff | Customer;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}
