import { Staff } from "../people";
import { PmlAssignment } from "./pml-assignment";

export interface PmlDispatcherPayment {
    id:         string;
    _id:        string;
    code:       string;
    dispatcher: Staff;
    assignment:  PmlAssignment[];
    amount:        number;
    paymentMethod: 'CASH' | 'WALLET' | 'BANK';
    status:        'PENDING' | 'SUCCESSFUL' | 'FAILED';
    createdAt:     Date;
    createdBy:     Staff;
    updatedAt:     Date;
    updatedBy:     Staff;
}

export class PmlDispatcherPayment {
    id:         string;
    _id:        string;
    code:       string;
    dispatcher: Staff;
    assignment:  PmlAssignment[];
    amount:        number;
    paymentMethod: 'CASH' | 'WALLET' | 'BANK';
    status:        'PENDING' | 'SUCCESSFUL' | 'FAILED';
    createdAt:     Date;
    createdBy:     Staff;
    updatedAt:     Date;
    updatedBy:     Staff;

    constructor(fields: any){
        for(let f of fields){
            this[f] = fields[f];
        }
    }
}