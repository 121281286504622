import { Staff } from '../people/staff';
import {PmlShipment} from "./pml-shipment";
import {Partner} from "../people";
import {Vehicle} from "../factory";
import {Terminal} from "../location";

export class PmlImpound {
    id: string;
    code: string;
    pmlShipment: PmlShipment;
    senderName: string;
    recipientName: string;
    senderPhone: string;
    recipientPhone: string;
    description: string;
    partner: Partner;
    vehicle: Vehicle;
    terminalFrom: Terminal;
    terminalTo: Terminal;
    terminalStore: Terminal;
    invoice: any;
    fine: number;
    shipmentCharge: number;
    paymentMethod: string;
    paymentGateway: string;
    paymentStatus: string;
    status: string;
    paymentDate: Date;
    impoundedDate: Date;
    impoundedBy: Staff;
    remark: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}

export interface PmlImpound {
// [prop: string]: any;
    id: string;
    code: string;
    pmlShipment: PmlShipment;
    senderName: string;
    recipientName: string;
    senderPhone: string;
    recipientPhone: string;
    description: string;
    partner: Partner;
    vehicle: Vehicle;
    terminalFrom: Terminal;
    terminalTo: Terminal;
    terminalStore: Terminal;
    invoice: any;
    fine: number;
    shipmentCharge: number;
    paymentMethod: string;
    paymentGateway: string;
    paymentStatus: string;
    status: string;
    paymentDate: Date;
    impoundedDate: Date;
    impoundedBy: Staff;
    remark: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}

