import {PmlTerminal} from './pml-terminal';

export class PmlRoute {
  id: string;
  name: string;
  pmlTerminalFrom: PmlTerminal;
  pmlTerminalTo: PmlTerminal;
  type: string;
  fuelLitresBus: number;
  fuelLitresTruck: number;
  distance: number;
  allowanceBusDriver: number;
  allowanceTruckDriver: number;
  durationBus: number;
  durationTruck: number;
  tripBus: number;
  tripTruck: number;
  billingTier: number;
  isActive: boolean;
  createdBy: any; // PmlStaff
  updatedBy: any; // PmlStaff
  deleted: boolean;
  deletedAt: Date;
  deletedBy: any; // PmlStaff

  constructor(fields: any) {
    // Quick and dirty extend/assign fields to this model
    for (const f in fields) {
      // @ts-ignore
      this[f] = fields[f];
    }
  }
}

export interface PmlRoute {
  id: string;
  name: string;
  pmlTerminalFrom: PmlTerminal;
  pmlTerminalTo: PmlTerminal;
  type: string;
  fuelLitresBus: number;
  fuelLitresTruck: number;
  distance: number;
  allowanceBusDriver: number;
  allowanceTruckDriver: number;
  durationBus: number;
  durationTruck: number;
  tripBus: number;
  tripTruck: number;
  billingTier: number;
  isActive: boolean;
  createdBy: any; // PmlStaff
  updatedBy: any; // PmlStaff
  deleted: boolean;
  deletedAt: Date;
  deletedBy: any; // PmlStaff
}
