import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ComponentsModule } from "./components/components.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import {
  DatePipe,
  HashLocationStrategy,
  LocationStrategy,
} from "@angular/common";
import { ToastrModule } from "ngx-toastr";

import { AppComponent } from "./app.component";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { PmlStoreManagerComponent } from "./pages/pml-store/pml-store-manager/pml-store-manager.component";

import { AppRoutes } from "./app.routing";
import {
  ApiService,
  AuthGuard,
  AuthService,
  DEFAULT_TIMEOUT,
  EnvService,
  ErrorInterceptor,
  InternetService,
  JwtInterceptor,
  TimeoutInterceptor,
} from "./services";
import {
  AccountHeadings,
  BankRegisters,
  Banks,
  BankTransactions,
  BulkReports,
  Categories,
  Cities,
  Counties,
  Customers,
  Expenses,
  Messages,
  Notifications,
  Partners,
  PmtRoutes,
  PosTransactions,
  Ratings,
  Settings,
  Staffs,
  States,
  Terminals,
  Tickets,
  Vehicles,
  Vouchers,
  PmlAssignments,
  PmlTerminals,
  PmlParcels,
  PmlPackages,
  PmlShipments,
  PmlSchedules,
  PmlRoutings,
  PmlImpounds,
  PmlVehicles,
  PmlDrivers,
  PmlReports,
  Offices,
  PmlAgents,
  PmlRegions,
  Reports,
  PmlZones,
  Roles,
  PmlStaffs,
  EcommerceCategories,
  EcommerceCustomers,
  PmlBillings,
  AnalyticsReports,
  PmlDispatcherPayments,
} from "./providers";

import { ConnectionServiceModule } from "ng-connection-service";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { LoginComponent } from "./pages/login/login.component";
import { ForgotPasswordComponent } from "./pages/forgot-password/forgot-password.component";
import { LoginOtpComponent } from "./pages/login-otp/login-otp.component";
import { NotificationService } from "./services/notification.service";
import { PmlRoutes } from "./providers/pml/pml-routes";
import { ResetPasswordComponent } from "./pages/reset-password/reset-password.component";
import { EcommerceManagerComponent } from "./pages/ecommerce/ecommerce-manager/ecommerce-manager.component";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { NgSelectModule } from "@ng-select/ng-select";
import { DataTablesModule } from "angular-datatables";
import { PmlDispatcherManagerComponent } from "./pages/pml-dispatcher/pml-dispatcher-manager/pml-dispatcher-manager.component";
import { AssignmentManagerComponent } from "./pages/pml-assignment/assignment-manager/assignment-manager.component";

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    LoginComponent,
    ForgotPasswordComponent,
    LoginOtpComponent,
    ResetPasswordComponent,
    EcommerceManagerComponent,
    PmlStoreManagerComponent,
    PmlDispatcherManagerComponent,
    AssignmentManagerComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes, {
      useHash: true,
    }),
    NgbModule,
    ToastrModule.forRoot(), // ToastrModule added
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ComponentsModule,
    NgxChartsModule,
    ConnectionServiceModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: "toast-top-right",
      preventDuplicates: true,
    }),
    NgSelectModule,
    DataTablesModule,
    // DataTablesModule.forRoot(),
  ],
  providers: [
    AuthService,
    ApiService,
    AuthGuard,
    EnvService,
    InternetService,
    NotificationService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    [{ provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true }],
    [{ provide: DEFAULT_TIMEOUT, useValue: 60000 }],
    AccountHeadings,
    Banks,
    BulkReports,
    Categories,
    Customers,
    Partners,
    Staffs,
    States,
    Cities,
    Counties,
    Vehicles,
    Ratings,
    PmtRoutes,
    BankRegisters,
    Messages,
    Notifications,
    Settings,
    Terminals,
    Vouchers,
    PosTransactions,
    BankTransactions,
    Tickets,
    Expenses,
    PmlTerminals,
    PmlParcels,
    PmlPackages,
    PmlShipments,
    PmlSchedules,
    PmlRoutings,
    PmlRoutes,
    PmlImpounds,
    PmlVehicles,
    Offices,
    PmlAgents,
    PmlRegions,
    PmlZones,
    Roles,
    PmlStaffs,
    EcommerceCategories,
    EcommerceCustomers,
    PmlDrivers,
    PmlBillings,
    DatePipe,
    AnalyticsReports,
    PmlReports,
    PmlAssignments,
    PmlDispatcherPayments,
    Reports,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
